import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles, FormControl, InputLabel, Select, Snackbar
} from '@material-ui/core';
import Context from '../Context';
import { initMicrophone } from '../audio';
import MuiAlert from '@material-ui/lab/Alert';
// import sentry from '../sentry';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  selectbox: {
  },
  select: {
    minWidth: 200,
  },
}));

export default function MicSelector({ onChange, recording, showWarning, setShowWarning }) {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setDeviceId, deviceId } = useContext(Context);
  // const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    initMicrophone().then(({ stream, devices: devs }) => {
      const dds = devs.filter((d) => d.kind === 'audioinput');
      setDevices(dds);
      setLoading(false);
      setTimeout(() => {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }, 500);
    }).catch((err) => {
      // sentry(err);
    });
    // Update local storage when the component first mounts
    var selectedDevice = null;
    if (deviceId) {
      selectedDevice = devices.find((d) => d.deviceId === deviceId);
    } else {
      selectedDevice = devices.find((d) => d.deviceId === 'default');
    }
    if (selectedDevice) {
      localStorage.setItem('selectedMicrophone', selectedDevice.label);
    }
  }, [devices, deviceId]);

  useEffect(() => {
    if (devices.length > 0) {
      var defaultDevice = null;
      if (deviceId) {
        defaultDevice = devices.find((d) => d.deviceId === deviceId);
      } else {
        defaultDevice = devices.find((d) => d.deviceId === 'default');
      }
      const searchParams = new URLSearchParams(window.location.search);
      const sanasAllowedValue = searchParams.get('sanasAllowed');
      if (defaultDevice && sanasAllowedValue !== 'true' && defaultDevice.label.toLowerCase().includes('sanas')) {
        setShowWarning(true);
      }
    }
  }, [devices, deviceId, setShowWarning]);

  if (loading) return null;

  const selectChange = (event) => {
    const selectedDeviceId = event.target.value;
    setDeviceId(selectedDeviceId);
    const selectedDevice = devices.find((d) => d.deviceId === selectedDeviceId);
    localStorage.setItem('selectedMicrophone', selectedDevice.label); 
    onChange && onChange();
    const searchParams = new URLSearchParams(window.location.search);
    const sanasAllowedValue = searchParams.get('sanasAllowed');
    if (sanasAllowedValue !== 'true' && selectedDevice.label.toLowerCase().includes('sanas')) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowWarning(false);
  };

  return (
    <div>
      {/* <Snackbar open={showWarning} autoHideDuration={6000} onClose={handleCloseWarning}> */}
      <Snackbar open={showWarning} autoHideDuration={6000} onClose={handleCloseWarning} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseWarning} severity="error">
          You cannot record using a Sanas microphone. Please select another microphone. 
        </Alert>
      </Snackbar>
      <FormControl className={classes.selectbox}>
      <InputLabel htmlFor="micselector">Select Microphone</InputLabel>
      <Select
        native
        onChange={selectChange}
        value={deviceId || ''}
        className={classes.select}
        disabled={recording}
      >
        {devices.map((d) => (
          <option value={d.deviceId} key={`-${d.deviceId}`}>{d.label}</option>
        ))}
      </Select>
    </FormControl>
    </div>
    
  );
}
