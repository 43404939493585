/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useContext, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Button, makeStyles } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import MicIcon from '@material-ui/icons/MicNone';
import Context from './Context';
import { Recorder } from './audio';
// import sentry from './sentry';
import wave from './lotties/wave.json';
import uploadingAnimation from './lotties/uploading.json';
import successAnimation from './lotties/success.json';
import Timer from './components/Timer';
import { getHumanReadableTime } from './components/Leaderboard';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0),
    width: '100%',
    textAlign: 'center',
  },
  mic: {
    margin: '20px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  please: {
    fontWeight: 'bolder',
  },
  timer: {
    display: 'inline-flex',
    width: '90px',
  },
}));

const RecorderApp = ({recording, setRecording}) => {
  const classes = useStyles();
  // const [recording, setRecording] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [totalRecordingLength, settotalRecordingLength] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { deviceId } = useContext(Context);
  const recorder = useRef();

  // console.log(recording, setRecording);


  const record = useCallback(() => {
    if (recording) {
      // Stop recording
      setRecording(false);
      setSubmitting(true);
      recorder.current.stop().then(async () => {
        recorder.current.release();
        recorder.current = null;
      });
    } else {
      // Start recording
      setSubmitSuccess(false);
      setInitializing(true);
      if (recorder.current) {
        recorder.current.stop();
        recorder.current.release();
      }
      recorder.current = new Recorder();
      recorder.current
        .init(deviceId)
        .then(() => {
          setInitializing(false);
          recorder.current.start(() => {
            setSubmitting(false);
            setSubmitSuccess(true);
          }, (rl) => {
            settotalRecordingLength(rl);
          }).then(() => {
            setRecording(true);
          });
        })
        .catch((err) => console.log(err));
    }
  }, [recorder, recording, deviceId, setRecording]);
    
  let hasSanasmicrophone = false;

  try {
    const selectedMicrophone = localStorage.getItem('selectedMicrophone');
    const enableSanasMicrophone = false; // Default to false, can be changed in the console

    // Check if the selected microphone contains 'sanas' and if the enableSanasMicrophone is true
    hasSanasmicrophone = selectedMicrophone && enableSanasMicrophone && selectedMicrophone.toLowerCase().includes('sanas');
  } catch (error) {
    console.error('Error accessing selected microphone:', error);
  }

  // console.log('recorder app rendering...');
  return (
    <div className={classes.submit}>
      {/* <Button
        onClick={logout}
        title="Sign out"
        className={classes.button}
        style={{ backgroundColor: '#f0f0f0', marginTop: '8px' }}
      >
        <span>Sign out</span>
      </Button> */}
      <Button
        // variant="contained"
        onClick={record}
        color="secondary"
        className={classes.button}
        disabled={initializing || submitting || hasSanasmicrophone}
        style={{ backgroundColor: '#f0f0f0', marginTop: '8px', padding: '8px 16px', minWidth: '160px',color: 'black' }} // Adjust button size
      >
        {recording ? (
          <>
            <StopIcon fontSize="medium" style={{ marginRight: '4px' }} /> 
            <span style={{ fontSize: '16px' }}>Stop Recording</span> 
          </>
        ) : (
          <>
            <MicIcon fontSize="medium" style={{ marginRight: '4px' }} />
            <span style={{ fontSize: '16px' }}>Start Recording</span>
          </>
        )}
      </Button>

      {(recording) && (
        <>
         {!hasSanasmicrophone && (
          <div style={{ pointerEvents: 'none' }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: wave,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={400}
            width={400}
            // isPaused={!recording}
          />
          </div>
         )}
          {recording && <Timer /> }
        </>
      )}

      {(initializing || submitting || submitSuccess) && (
        <>
          <Lottie
            options={{
              loop: initializing || submitting,
              autoplay: true,
              animationData: initializing || submitting ? uploadingAnimation : successAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={400}
            width={400}
          />
          {initializing && 'Initializing...'}
          {submitting && 'Finalizing the recording...'}
          {submitSuccess && totalRecordingLength && <div>Recorded {getHumanReadableTime(totalRecordingLength)} today</div>}
        </>
      )}
    </div>
  );
};

export default React.memo(RecorderApp);
