/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { getAuth } from '../auth';
import Context from '../Context';
import ConsentForm from './ConsentForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const { signIn, loading } = useContext(Context);
  // const { user, signIn, signing, loading } = useContext(Context);

  const { handleSubmit, reset, control } = useForm();
  const [signingIn, setSigningIn] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const [groupId, setGroupId] = useState('');

  const onSubmit = async (data) => {
    setSigningIn(true);
    try {
      if (groupIdRequiresConsent(data.groupId)) {
        setGroupId(data.groupId);
        window.localStorage.setItem('groupId',data.groupId);
        setShowConsentForm(true);
      } else {
        await signIn(data.groupId);
      }
    } catch (error) {
      // Handle error
    } finally {
      setSigningIn(false);
    }
  };

  const handleConsentSubmit = async (consent) => {
    // Logic to store consent in the database
    await signIn(groupId);
  };

  useEffect(() => {
    const auth = getAuth();
    reset(auth);
  }, [reset]);

  if (loading) return null;

  return (
    <div>
      {showConsentForm ? (
        <ConsentForm onSubmit={handleConsentSubmit} groupId={groupId} />
      ) : (
        <form data-testid="login-form" id="login-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6">Login</Typography>
          <Controller
            name="groupId"
            control={control}
            defaultValue="" // Add this line to set initial value
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Group ID"
                autoComplete="groupId"
                autoFocus
                {...field}
                value={field.value || ''} // Ensure value is never null
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={signingIn}
          >
            {signingIn ? 'Signing In...' : 'Sign In'}
          </Button>
        </form>
      )}
    </div>
  );
}

function groupIdRequiresConsent(groupId) {
  const consentRequiredGroupIds = ['TP-latam-colombia', 'TP-latam-peru'];
  return consentRequiredGroupIds.includes(groupId);
}
