import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, TextField, Typography, makeStyles, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Context from '../Context';
import { storeConsent } from '../db';
import sha256 from 'crypto-js/sha256';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
    margin: '0 auto',
  },
  input: {
    margin: theme.spacing(1),
    width: '100%',
  },
  button: {
    margin: theme.spacing(2),
    width: '100%',
  },
  privacyLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
    textDecoration: 'none',
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'black',
    },
  },
}));

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // Returns YYYY-MM-DD
};

// Replace existing validation with this comprehensive approach
const validateField = (name, value) => {
  switch(name) {
    case 'fullName':
      return /^[A-Za-z\s]{2,50}$/.test(value) ? '' : 'Please enter a valid name';
    case 'country':
      return /^[A-Za-z\s]{2,50}$/.test(value) ? '' : 'Please enter a valid country';
    case 'city':
      return /^[A-Za-z\s]{2,50}$/.test(value) ? '' : 'Please enter a valid city';
    case 'cardNumber':
      return /^[A-Za-z0-9]{5,20}$/.test(value) ? '' : 'Please enter a valid card number';
    case 'consent':
      return value ? '' : 'You must accept the terms';
    default:
      return '';
  }
};

export default function ConsentForm({ onSubmit, groupId }) {

  const { signIn } = useContext(Context);
  // const { user, signIn, signing, loading } = useContext(Context);
  
  const classes = useStyles();
  const [formData, setFormData] = useState({
    fullName: '',
    country: '',
    city: '',
    cardNumber: '',
    date: getTodayDate(),
    consent: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newValue = name === 'consent' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Validate on change
    const error = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formIsValid = Object.keys(formData).every(key => !validateField(key, formData[key]));

    if (formIsValid) {
      try {
        const groupId = window.localStorage.getItem('groupId');
        const timestamp = new Date().toISOString(); // Full timestamp for uniqueness
        
        // Generate hash including timestamp
        const hashArray = [
          groupId,
          formData.fullName.toLowerCase().trim(),
          formData.country.toLowerCase().trim(),
          formData.city.toLowerCase().trim(),
          formData.cardNumber.trim(),
          timestamp // Use full timestamp instead of just date
        ];
        
        const userHash = sha256(hashArray.join('-')).toString();

        await signIn(groupId);

        await storeConsent({
          ...formData,
          userHash,
          dateOfConsent: timestamp.split('T')[0], // Keep date for reference
          timestamp: timestamp, // Add full timestamp
          groupId: groupId,
          updatedAt: timestamp
        });

        if (isMounted.current) {
          setSuccessMessage('Consent form submitted successfully');
          setFormData({
            fullName: '',
            country: '',
            city: '',
            cardNumber: '',
            date: getTodayDate(),
            consent: false
          });
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted.current) {
          console.error('Form submission failed:', error);
          setErrors({ 
            submit: error.message || 'Failed to submit form. Please try again.' 
          });
          setIsLoading(false);
        }
      }
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      formData.fullName &&
      formData.country &&
      formData.city &&
      formData.cardNumber &&
      formData.date &&
      formData.consent
    );
  };

  const getPrivacyPolicyLink = () => {
    if (groupId === 'TP-latam-colombia') {
      return '/privacy_policy_colombia.pdf';
    } else if (groupId === 'TP-latam-peru') {
      return '/privacy_policy_peru.pdf';
    } else {
      return '/privacy_policy.pdf';
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography variant="h6">Consent Form</Typography>
        <TextField
          className={classes.input}
          label="Full Legal Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          error={!!errors.fullName}
          helperText={errors.fullName}
          required
        />
        <TextField
          className={classes.input}
          label="Country of Citizenship"
          name="country"
          value={formData.country}
          onChange={handleChange}
          error={!!errors.country}
          helperText={errors.country}
          required
        />
        <TextField
          className={classes.input}
          label="City"
          name="city"
          value={formData.city}
          onChange={handleChange}
          error={!!errors.city}
          helperText={errors.city}
          required
        />
        <TextField
          className={classes.input}
          label="Citizenship Card Number"
          name="cardNumber"
          value={formData.cardNumber}
          onChange={handleChange}
          required
        />
        <TextField
          className={classes.input}
          label="Date"
          name="date"
          type="date"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{readOnly: true}}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
              required
              className={classes.checkbox}
            />
          }
          label="I confirm that I have reviewed the privacy policy and consent to its terms and conditions."
        />
        <a href={getPrivacyPolicyLink()} target="_blank" rel="noopener noreferrer" className={classes.privacyLink}>
          <img src="/privacy_policy.svg" alt="Privacy Icon" style={{ marginRight: '5px' }} />
          View Privacy Policy
        </a>
        {errors.submit && (
          <Typography className={classes.errorText}>
            {errors.submit}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!isFormValid() || isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Next'}
        </Button>
      </form>

      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={6000} 
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

ConsentForm.propTypes = {
  onSubmit: PropTypes.func
};

ConsentForm.defaultProps = {
  onSubmit: () => {}
};
