import { S3 } from '@aws-sdk/client-s3';
import { Auth } from 'aws-amplify';
import { getAuth } from './auth';
import config from './config';
import { ackUpload } from './db';

const getMetaPath = () => {
  const { groupId } = getAuth();
  return `cc_continuous/${groupId}`;
};

export const getS3 = async () => {
  const credentials = await Auth.currentCredentials();
  return new S3({
    region: config.region,
    credentials,
  });
};

export default class UploadService {
  constructor() {
    const sessionId = `${Date.now()}`;
    const groupId = window.localStorage.getItem('groupId');
    const {firstname , lastname , gender , accent, country } = JSON.parse(window.localStorage.getItem(`metadataV3_${groupId}`)) || {};
    const fileName = `${firstname}_${lastname}_${gender}_${country}-${accent}_${sessionId}`.replaceAll(/\s/g, '');
    this.key = `${getMetaPath()}/${fileName}.wav`;
    this.uploadId = '';
    this.partNumberEtagMap = [];
    this.uploadQueue = 0;
    this.parts = 0;
  }

  async start() {
    const client = await getS3();
    const groupId = window.localStorage.getItem('groupId');
    const meta = JSON.parse(window.localStorage.getItem(`metadataV3_${groupId}`)) || {};
    const microphone = localStorage.getItem('selectedMicrophone'); 
    const { UploadId } = await client.createMultipartUpload({
      Bucket: config.bucket,
      Key: this.key,
      Metadata: {
        firstname: encodeURIComponent(meta.firstname),
        lastname: encodeURIComponent(meta.lastname),
        gender: encodeURIComponent(meta.gender),
        age: encodeURIComponent(meta.age),
        accent: encodeURIComponent(meta.accent),
        userHash: encodeURIComponent(meta.hash),
        browser: navigator.userAgent,
        microphone : encodeURIComponent(microphone)
      },
    });
    this.uploadId = UploadId;
  }

  async upload(Body, lastChunk, onLastSuccess) {

    if (!this.uploadId) {
      await this.start();
    }

    this.uploadQueue += 1;
    this.parts += 1;
    this.lastChunk = lastChunk;

    let PartNumber = this.parts;

    const client = await getS3();
    const { ETag } = await client.uploadPart({
      Body,
      Bucket: config.bucket,
      Key: this.key,
      PartNumber,
      UploadId: this.uploadId,
    });

    this.partNumberEtagMap.push({
      ETag,
      PartNumber,
    });

    this.uploadQueue -= 1;

    // console.log('Upload queue length', this.uploadQueue);

    if (this.lastChunk && this.uploadQueue === 0) {
      await this.complete(client);
      if (onLastSuccess) {
        
      }
      ackUpload();
      // console.log('finished the mutipart upload and updated chunk');
    }
  }

  async complete(client) {
    return client.completeMultipartUpload({
      Bucket: config.bucket,
      Key: this.key,
      MultipartUpload: {
        Parts: this.partNumberEtagMap.sort((a, b) => a.PartNumber - b.PartNumber),
      },
      UploadId: this.uploadId,
    });
    
  }
}
