// eslint-disable-next-line import/no-anonymous-default-export
export default {
  debug: false,
  region: 'us-west-2',
  bucket: 'asr-collection',
  dynamoDbRegion: 'us-east-1',
  leadershipTable: 'callcenter_leadership_board',
  consentTable: 'user-consent-table', // or appropriate table name
  // consentTable: 'user-consent-table-dev', // or appropriate table name
};
